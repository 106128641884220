import './App.css';
import React, { useEffect, useState } from 'react';
import Login from './components/Authentication/Login';
import SignUp from './components/Authentication/SignUp';
import PasswordReset from './components/Authentication/PasswordReset';
import {Routes,Route} from "react-router-dom"
import RegisterForm from './components/Forms/RegisterForm';
import UserHome from './components/Pages/UserHome';
import { createContext } from 'react';
import ContactUs from './components/Pages/ContactUs';
import BookAppointment from "./components/Forms/BookAppointment"
import EmailVerify from './components/Authentication/EmailVerify';
import UpdatePassword from "./components/Authentication/UpdatePassword"
import AgentForm from './components/Forms/AgentForm';
import UserApplications from './components/Pages/UserApplications';
import AgentApplications from './components/Pages/AgentApplications';
import PageNotFound from './components/Pages/PageNotFound';
import AgentHome from './components/Pages/AgentHome';
import AgentEmailVerification from './components/Authentication/AgentEmailVerification';
import AgentLogin from "./components/Authentication/AgentLogin"
import AgentPasswordReset from './components/Authentication/AgentPasswordReset';
import AgentUpdatePassword from "./components/Authentication/AgentUpdatePassword"
import AppCheck from './PaymentGateway';
import PaymentPage from './PaymentGateway/PaymentPage';
import AppInfo from './components/Pages/AppInfo';
import AgentAppInfo from './components/Pages/AgentAppInfo';
export const LoginStatus = createContext()
 
function App() {

  const [isLogin,setIsLogin] = useState(false)

  useEffect(() =>{
    
  },[])

  return (
    <LoginStatus.Provider value={{isLogin,setIsLogin}}>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/agent_login' element={<AgentLogin />} />
        <Route path='/home' element={<UserHome />} />
        <Route path='/registrationform/:app_name' element={<RegisterForm />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/passwordreset' element={<PasswordReset />} />
        <Route path='/agent_passwordreset' element={<AgentPasswordReset />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/bookappointment' element={<BookAppointment />} />
        <Route path='/my_applications' element={<UserApplications />} />
        <Route path='/my_files' element={<AgentApplications />} />
        <Route path='/agent_form' element={<AgentForm />} />
        <Route path='/agent_home' element={<AgentHome />} />
        <Route path='/verify_email/:id' element={<EmailVerify />} />
        <Route path='/agent_verify_email/:id' element={<AgentEmailVerification />} />
        <Route path='/forgot_password/:id' element={<UpdatePassword />} />
        <Route path='/agent_forgot_password/:id' element={<AgentUpdatePassword />} />
        <Route path='/pay' element={<AppCheck />} />
        <Route path='/payment' element={<PaymentPage />} />
        <Route path='/app_info/:id' element={<AppInfo />} />
        <Route path='/agent_app_info/:id' element={<AgentAppInfo />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </LoginStatus.Provider>
  );
}

export default App;
