import React, { useContext, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import style from "./Login.module.css"
import UserHeader from "../partialpages/UserHeader"
import {Link }from "react-router-dom"
import {useNavigate,useSearchParams} from "react-router-dom"
import {LoginStatus} from "../../App"
import axios from "axios"
import {LOGIN_API} from "../../API/index"
import Loader from "../Utilities/Loader"
import Footer from '../partialpages/Footer';
import {userLogin} from "../../Validations/userValidations"

function Login() {
    const [passwordType,setPasswordType] = useState("password")
    const [currentErrors, setCurrentErrors] = useState([])
    const isLogin = useContext(LoginStatus)
    const navigate = useNavigate()
    const [email,setEmail] = useState("")
    const [isLoading,setIsLoading] = useState(false)
    const [password,setPassword] = useState("")

    const [queryParams,setQueryParams] = useSearchParams()
   
    useEffect(() =>{
        if(queryParams.get("flag") !== null){
            if(queryParams.get("flag") === "success"){
                toast.success(queryParams.get("msg"),{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
                setTimeout(() =>{
                    queryParams.delete("flag")
                    setQueryParams(queryParams)
                },1000)
            }else{
                toast.error(queryParams.get("msg"),{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
                setTimeout(() =>{
                    queryParams.delete("flag")
                    setQueryParams(queryParams)
                },500)
            }
        }
        
    },[])

    const emailHandler = (e) => {
        setEmail(e.target.value)
    }
    const passwordHandler = (e) => {
        setPassword(e.target.value)
    }

    const getCredentials = () =>{
        const data = {
            email:email,
            password:password
        }
        userLogin.validate(data,{ abortEarly: false })
        .then((res) =>{
            setIsLoading(true)
            const url = LOGIN_API
            const config = {
                headers:{
                    "secret_key":"K9J920XCVK5B609d263bf3d777c31c234ced",
                    "Content-Type":"application/json"
                }
            }
        
            axios.post(url,data,config)
            .then(
                (response) =>{
                    const result = JSON.parse(response.data)
                    if(result.code === 1){
                        sessionStorage.setItem("userToken",result.data.token)
                        sessionStorage.setItem("userEmail",result.data.Email)
                        setIsLoading(false)
                        isLogin.setIsLogin(true)
                        setEmail("")
                        setPassword("") 
                        navigate(`/home?msg=${result.message}&flag=success`)
                    }else{
                        setIsLoading(false)
                        toast.error(result.message,{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                        isLogin.setIsLogin(false)
                        setPassword("")
                        setEmail("") 
                        navigate("/?loginflag=user")
                    }
                }
            ).catch((error) =>{
                setIsLoading(false)
                toast.error(error.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            })
            setCurrentErrors([])
        }).catch((err) =>{
            setCurrentErrors(err.errors)
        })
    }

    const loginHandler = (e) =>{
        e.preventDefault()
        getCredentials()
    }
    
    const showHide = () =>{
        if(passwordType === "password"){
            setPasswordType("text")
        }else{
            setPasswordType("password")
        }
    }
  return (
    <>
        <UserHeader />
        <div className={style.container}>
            <div className={style.wrapper}>
                <form>
                    <div className={style.formContainer}>
                        <div className={style.welcome}>
                            <h1>Welcome back</h1>
                            <p>One login for all Immigration Services</p>
                        </div>
                        <div className={style.inputContainer}>
                            <div className={style.inputField}>
                                <div>
                                    <div>
                                        <input type="text" required  value={email} onChange={emailHandler}/>
                                        <label>Email address</label>
                                    </div>
                                    {currentErrors.map((e,index) =>(
                                        <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"3px",
                                        }}  key={index}>{e.email}</div>
                                    ))
                                    }
                                </div>
                                <div>
                                    <div>
                                        <input type={passwordType} value={password} required onChange={passwordHandler} />
                                        <label>Password</label>
                                    </div>
                                        <div className={style.hidePassword}>
                                            {
                                                passwordType === "password" ? <i onClick={showHide} className="fa-solid fa-eye-slash"></i> 
                                                :
                                                <i onClick={showHide}  className="fa-solid fa-eye"></i>
                                            }
                                        </div>
                                    {currentErrors.map((e,index) =>(
                                            <div style={{
                                                fontSize:"0.7rem",
                                                fontWeight:"700",
                                                color:"red",
                                                marginTop:"3px",
                                            }} key={index}>{e.pass}</div>
                                        ))
                                    }
                                </div>
                                <div className={style.forgotPassword}>
                                    <span>
                                        <Link to='/passwordreset'>Forgot password</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={style.loginBtn}>
                                <button className={`${isLoading && style.disableBtn}`} onClick={loginHandler} type='submit'>Login</button>
                        </div>
                        <div className={style.notHaveAcc}>
                            <span>Don't have an account ? <Link to='/signup'>Sign up</Link></span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
            
        }
        <ToastContainer />
    </>
  )
}

export default Login