import React, { useEffect, useState } from 'react'
import style from "./UserApplications.module.css"
import UserHeader from "../partialpages/UserHeader"
import axios from 'axios'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {MY_FILES_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import {Link,useNavigate} from "react-router-dom"
import {FILES_AMOUNT} from "../../Constants/index"
import Footer from '../partialpages/Footer'
import {Base64} from "../../EncryptDecrypt/index"

function UserApplications() {
    const [applicationLoading,setApplicationLoading] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [userInfo,setUserInfo] = useState({})
    const [status,setStatus] = useState(false)
    const navigate = useNavigate()
    useEffect(() =>{
        const getApplications = () =>{
            setIsLoading(true)
            const url = MY_FILES_API
            const token = sessionStorage.getItem("userToken")
            const config = {
                headers:{
                    "token":token,
                    'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                    "Content-Type": "application/json",
                }
            }
            axios.post(url,{},config)
            .then(
                (response) =>{
                    const result = JSON.parse(response.data)
                    setUserInfo(result)
                    if(result.code === 1){
                        setUserInfo(result)
                        setStatus(true)
                        setIsLoading(false)
                        setApplicationLoading(true)
                        toast.success(result.message,{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                    }else{
                        setStatus(false)
                        setApplicationLoading(true)
                        setIsLoading(false)
                        toast.error("No Application Found",{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                    }
                }
            ).catch((error) =>{
                setIsLoading(false)
                toast.error(error.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            })
        }
        getApplications()
    },[])

    const applicationInfoHandler =  (id) =>{
        navigate("/app_info/"+id)
    }
    
    const payHandler = (id,app,amount) =>{
        const url = Base64.encode(`tid=${id}&appName=${app}&amount=${amount}&description=To Know more about ${app}`)
        navigate(`/pay?code=${url}`)
    }

  return (
    <>
        <UserHeader />
        <div className={style.homeContainer}>
            <div className={style.homeWrapper}>
                <div className={style.homeMessage}>
                    <h1>My Applications</h1>
                </div>
                <div className={style.homeOptions}>
                    {
                        applicationLoading ? 
                        <>
                        
                        {
                        status ? <>
                        {
                            userInfo.data.info.count > 0 ?
                            <>
                            {
                                userInfo.data.data.map((item,index) =>(

                                  
                                    <div className={style.application} key={index}>
                                        <div className={style.imgContainer}>
                                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                                        </div>
                                        <div className={style.infoApplication} onClick={() => applicationInfoHandler(item.id)}>
                                            <h1>{item.Application_Type}</h1>
                                            <span>To Know more about {item.Application_Type}</span>
                                        </div>
                                        <div className={style.rightPart}>
                                            <div className={style.status}>
                                                <h3>Status</h3>
                                                <span>{item.Status}</span>
                                            </div>
                                            <div className={style.payment}>
                                                <h3>Payment</h3>
                                                {
                                                item.Transaction_Id
                                                    ? <button key={index} style={{backgroundColor:"green",
                                                color:"white"}}>Paid</button> 
                                                : 
                                                <button key={index} onClick={() => payHandler(item.id,item.Application_Type,FILES_AMOUNT[item.Application_Type])} style={{backgroundColor:"red",
                                                color:"white"}}>Pay Now</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            </>
                                :
                            <div>
                                <h1>You have not registered yet</h1>
                            </div>

                            }   
                            </>
                        :
                        <div style={{
                            width:"100%",
                            height:"inherit",
                            display:"flex",
                            flexDirection:"column",
                            gap:"1rem",
                            justifyContent:"center",
                            alignItems:"center"
                        }}>
                            <h1 style={{
                                width:"100%",
                                textAlign:"center",
                            }}>No Application Found</h1>
                        </div>
                    }
                        
                        </>
                        :
                        <>
                        <div>
                            <h1 className={style.appLoad}>Application Loading...</h1>
                        </div>
                        </>
                    }
                        <Link className={style.startApplication}  to="/home">Start a new application</Link>
                  
                </div>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
        }
        <ToastContainer />
    </>
  )
}

export default  UserApplications