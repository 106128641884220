import React from 'react'
import style from './PageNotFound.module.css'

function PageNotFound() {
  return (
    <div className={style.container}>
        <h1>Bad Request....</h1>
        <h1>404 Page Note Found</h1>
    </div>
  )
}

export default PageNotFound