import React, { useState,useEffect } from 'react'
import style from "./RegisterForm.module.css"
import UserHeader from "../partialpages/UserHeader"
import FormData from "form-data"
import axios from 'axios'
import {useNavigate,useParams} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UPLOAD_FILES_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Autocomplete from "react-google-autocomplete"
import Footer from '../partialpages/Footer'
import {registerForm} from "../../Validations/userValidations"
import {FILES_AMOUNT} from "../../Constants/index"
import { Base64 } from '../../EncryptDecrypt'

function RegisterForm() {

    const {app_name} = useParams()

    const [edu,setEdu] = useState(false)
    const [pC,setPC] = useState(false)
    const [pI,setPI] = useState(false)
    const [passF,setPassF] = useState(false)
    const [passB,setPassB] = useState(false)
    const [passA,setPassA] = useState(false)
    const [p,setP] = useState(false)
    const [mE,setME] = useState(false)
    const [address,setAddress] = useState("")
    const [errorGender,setErrorGender] = useState()
    const [errorMarry,setErrorMarry] = useState()
    const [currentErrors, setCurrentErrors] = useState([])
    const sendData = new FormData()
    const navigate = useNavigate()
    const [isLoading,setIsLoading] = useState(false)
    const files = {
        passport_front: undefined,
        passport_back: undefined,
        passport_all: undefined,
        medical_exam: undefined,
        police_india: undefined,
        police_canada: undefined,
        photo:undefined,
        education:undefined,

    }

    const myData = {
        uci_number:"",
        first_name:"",
        last_name:"",
        marital_status:"What is your marital status?",
        gender:"What is your gender?",
    }

    const [formData,setFormData] = useState(myData)
    const [step,setStep] = useState(1)
    const [allFiles,setAllFiles] = useState(files)

    useEffect(()=>{
      
    },[allFiles,formData])

    const passportFrontFileUploadHandle = (e) =>{
        setAllFiles({
            ...allFiles,
            passport_front:e.target.files[0],
        })
    }

    const passportBackFileUploadHandle = (e) =>{
        setAllFiles({
            ...allFiles,
            passport_back:e.target.files[0],
        })
    }
    const passportAllFileUploadHandle = (e) =>{
        setAllFiles({
            ...allFiles,
            passport_all:e.target.files[0],
        })
    }

    const policeCanadaFileUploadHandle = (e) =>{
        setAllFiles({
            ...allFiles,
            police_canada:e.target.files[0],
        })
    }

    const policeIndiaFileUploadHandle = (e) =>{
        setAllFiles({
            ...allFiles,
            police_india:e.target.files[0],
        })
    }
    const educationFileUploadHandle = (e) =>{
        setAllFiles({
            ...allFiles,
            education:e.target.files[0],
        })
        
    }
    const medicalExamFileUploadHandle = (e) =>{
        if(e.target.files[0].size < 5000000){
            setAllFiles({
                ...allFiles,
                medical_exam:e.target.files[0],
            })
        }else{
            alert("file must be smaller than 5MB")
        }  
    }
    const digitalPhotoFileUploadHandle = (e) =>{
            setAllFiles({
                ...allFiles,
                photo:e.target.files[0],
            })
    }

    const formDataHandler = (e) =>{
        const name = e.target.name
        const val = e.target.value
        setFormData(
            {
                ...formData,
                [name]:val,
            }
        )
    }
    
    const stepHandle = () =>{
      
        const data = {
            uci_number:formData.uci_number,
            last_name:formData.last_name,
            first_name:formData.first_name
        }

        if( formData.marital_status === "What is your marital status?"){
            setErrorMarry(true)
        }else{
            setErrorMarry(false)
        }
        if(formData.gender.length === 20){
           setErrorGender(true)
        }else{
            setErrorGender(false)
        }
        registerForm.validate(data,{ abortEarly: false })
        .then((res) =>{     
            if(formData.gender === "What is your gender?" || formData.marital_status === "What is your marital status?"){
              console.log("pass")
            }else{
                setStep(2)
            }
            setCurrentErrors([])
        }).catch((err) =>{
            setCurrentErrors(err.errors)
        })
    }


    const prevHandler = () =>{
        setStep(1)
    }


    const submitHandler = (e) =>{
        e.preventDefault()
        if(allFiles.education === undefined || allFiles.medical_exam === undefined || allFiles.passport_all === undefined || allFiles.passport_back === undefined || allFiles.passport_front === undefined || allFiles.police_canada === undefined || allFiles.police_india === undefined || allFiles.photo === undefined ){

            allFiles.education === undefined ? setEdu(true) : setEdu(false)
            allFiles.passport_all === undefined ? setPassA(true) : setPassA(false)
            allFiles.passport_back === undefined ? setPassB(true) : setPassB(false)
            allFiles.passport_front === undefined ? setPassF(true) : setPassF(false)
            allFiles.police_canada === undefined ? setPC(true) : setPC(false)
            allFiles.police_india === undefined ? setPI(true) : setPI(false)
            allFiles.photo === undefined ? setP(true) : setP(false)
            allFiles.medical_exam === undefined ? setME(true) : setME(false)
        }else{
            setIsLoading(true)
            for(let key in allFiles){
               sendData.append(key,allFiles[key])
            }
            for(let key in formData){
                sendData.append(key,formData[key])
            }
            sendData.append("application_type",app_name)
            sendData.append("current_address",address)
            const token = sessionStorage.getItem("userToken")
            const url = UPLOAD_FILES_API
            const config = {
                headers:{
                    "token":token,
                    'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                    "Content-Type": "multipart/form-data",
                }
            }
    
            const data = {
                ...allFiles,
                ...formData,
                current_address:address,
                application_type:app_name
            }
            axios.post(url,data,config)
            .then(
                (response) =>{
                    console.log(JSON.parse(response.data))
                    const result = JSON.parse(response.data)
                    if(result.code === 1){
                        setIsLoading(false)
                        const url = Base64.encode(`tid=${result.data.stack_immigration_id
                        }&amount=${FILES_AMOUNT[app_name]}&appName=${app_name}&description=To know more about ${app_name}`)
                        navigate(`/payment?msg=${result.message}&flag=success&code=${url}`)
                    }else{
                        setIsLoading(false)
                        toast.error(result.message,{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                        navigate(`/registrationform`)
                    }   
                }
            ).catch((error) =>{
                setIsLoading(false)
                toast.error(error.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            })
        }

    }
  return (
    <> 
        <UserHeader />
        <div className={style.container}>

                <div className={style.application}>
                    <div className={style.imgContainer}>
                        <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                    </div>
                    <div className={style.infoApplication}>
                        <h1> {app_name} </h1>
                        <span>To Know more about {app_name}</span>
                    </div>
                    <div className={style.price}>
                        <h4>Price</h4>
                        <h1>${FILES_AMOUNT[app_name]}</h1>
                    </div>
                </div>

            
            <div className={style.wrapper}>
                {step === 1 ? <h1>Personal Information</h1> : <h1>Required Files</h1>}
                <form>
                    {
                        step === 1 ?
                        <div className={style.stepFirst}>
                            <div className={style.containerData}>
                                <div className={style.left}>
                                    <div>
                                        <div>
                                            <input type="text" value={formData.uci_number} name="uci_number" required onChange={formDataHandler}/>
                                            <label>UCI Number</label>
                                        </div>
                                        {currentErrors.map((e,index) =>(
                                            <div style={{
                                                fontSize:"0.7rem",
                                                fontWeight:"700",
                                                color:"red",
                                                marginTop:"3px",
                                            }}  key={index}>{e.uci_number}</div>
                                            ))
                                        }
                                    </div>
                                    <div>
                                        <div>
                                            <input type="text" value={formData.last_name} name="last_name" required onChange={formDataHandler}/>
                                            <label>What is your last name ?</label>
                                        </div>
                                        {currentErrors.map((e,index) =>(
                                            <div style={{
                                                fontSize:"0.7rem",
                                                fontWeight:"700",
                                                color:"red",
                                                marginTop:"3px",
                                            }}  key={index}>{e.last_name}</div>
                                            ))
                                        }
                                    </div>
                                    <div className={style.desktop} >
                                        <div>
                                            <select onChange={formDataHandler} name="gender" value={formData.gender}> 
                                                <option selected  disabled>What is your gender?</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Unknown">Unknown</option>
                                                <option value="Anther Gender">Another Gender</option>
                                            </select>
                                        </div>
                                        {
                                        errorGender && <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"3px",}}>
                                                Please select one option
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className={style.right}>
                                    <div>
                                        <div>
                                            <input type="text" value={formData.first_name} name="first_name" required onChange={formDataHandler}/>
                                            <label>What is your first name ?</label>
                                        </div>
                                        {currentErrors.map((e,index) =>(
                                            <div style={{
                                                fontSize:"0.7rem",
                                                fontWeight:"700",
                                                color:"red",
                                                marginTop:"3px",
                                            }}  key={index}>{e.first_name}</div>
                                            ))
                                        }
                                    </div>
                                    <div className={style.mobile}>
                                        <div>
                                            <select onChange={formDataHandler} name="gender" value={formData.gender}> 
                                                <option selected disabled>What is your gender?</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Unknown">Unknown</option>
                                                <option value="Anther Gender">Another Gender</option>
                                            </select>
                                        </div>
                                        {
                                            errorGender && <div style={{
                                                fontSize:"0.7rem",
                                                fontWeight:"700",
                                                color:"red",
                                                marginTop:"3px",}}>
                                                    Please select one option
                                                </div>
                                        }
                                    </div>
                                    <div>
                                        <div>
                                            <select onChange={formDataHandler} name="marital_status" value={formData.marital_status}>
                                                <option selected disabled>What is your marital status?</option>
                                                <option value="Married">Married</option>
                                                <option value="Legally Separated">Legally Separated</option>
                                                <option value="Divorced">Divorced</option>
                                                <option value="Annulled Marriage">Annulled Marriage</option>
                                                <option value="Widowed">Widowed</option>
                                                <option value="Common-Law">Common-Law</option>
                                                <option value="Never Married/Single">Never Married/Single</option>
                                            </select>
                                        </div>
                                        {
                                            errorMarry && <div style={{
                                                fontSize:"0.7rem",
                                                fontWeight:"700",
                                                color:"red",
                                                marginTop:"3px",}}>
                                                    Please select one option
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={style.address}>
                                <div>
                                    <Autocomplete
                                        apiKey="AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU"
                                        style={{ width: "100%" }}
                                        onPlaceSelected={(place) => {
                                            setAddress(place.formatted_address)
                                        }}
                                        defaultValue={address}
                                        options={{
                                            componentRestrictions: { country: ["ca","in"], },
                                            types: ["geocode", "establishment"],
                                          }}
                                        placeholder='Current Address'
                                    />
                                    <label>Current Address</label>
                                </div>
                            </div>
                            <div className={style.next}>
                                <button type='button' onClick={stepHandle}>Next</button>
                            </div>
                        </div>
                        :
                        <div className={style.fileUpload}>
                            <div className={style.fileWrapper}>
                                <div className={style.leftFile}>
                                    <div>
                                        <label>Passport Front</label>
                                        <input type="file" accept='application/pdf'  name='passport_front' onChange={passportFrontFileUploadHandle} className={`${passF ? style.fileError : style.normal}`} />
                                    </div>
                                    <div>
                                        <label>Passport Back</label>
                                        <input type="file" accept='application/pdf'  name='passport_back'  onChange={passportBackFileUploadHandle} className={`${passB ? style.fileError : style.normal}`} />
                                    </div>
                                    <div>
                                        <label>Passport All Pages</label>
                                        <input type="file" accept='application/pdf'  name='passport_all' onChange={passportAllFileUploadHandle} className={`${passA ? style.fileError : style.normal}`} />
                                    </div>
                                    <div>
                                        <label>Education Transcripts</label>
                                        <input type="file" accept='application/pdf'  name='education' onChange={educationFileUploadHandle} className={`${edu ? style.fileError : style.normal}`} />
                                    </div>
                                </div>
                                <div className={style.rightFile}>
                                    <div>
                                        <label>Police Certificate (Canada)</label>
                                        <input type="file" accept='application/pdf'  name='police_canada' onChange={policeCanadaFileUploadHandle} className={`${pC ? style.fileError : style.normal}`} />
    
                                    </div>
                                    <div>
                                        <label>Police Certificate (India)</label>
                                        <input type="file" accept='application/pdf'  name='police_india' onChange={policeIndiaFileUploadHandle} className={`${pI ? style.fileError : style.normal}`} />
                                    </div>
                                    <div>
                                        <label>Proof of medical exam</label>
                                        <input type="file" accept='application/pdf' name='medical_exam' onChange={medicalExamFileUploadHandle} className={`${mE ? style.fileError : style.normal}`} />
                                    </div>
                                    <div>
                                        <label>Digital Photo</label>
                                        <input type="file" accept="image/jpg"  name='photo' onChange={digitalPhotoFileUploadHandle} className={`${p ? style.fileError : style.normal}`} />
                                    </div>
                                </div>
                            </div>
                            <div className={style.submitDiv}>
                                <div className={style.prev}>
                                    <button type='button' className={`${isLoading && style.disableBtn}`}  onClick={prevHandler}>Previous</button>
                                </div>
                                <div className={style.submit}>
                                    <button className={`${isLoading && style.disableBtn}`} onClick={submitHandler}>Submit</button>
                                </div>
                            </div>   
                        </div>
                    }
                </form>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
            
        }
        <ToastContainer />

    </>
  )
}

export default RegisterForm