import * as yup from "yup"

export const agentLogin = yup.object({
    email:yup.string().email({email:"Invalid Email Address"}).required({email:"This field is Required"}),
    password:yup.string().required().min(5,{pass:"Must be greater than 5 char"}),
})

export const agentForgotPassword = yup.object({
    email:yup.string().email({email:"Invalid Email Address"}).required({email:"This field is Required"}),
})


export const becomePartner = yup.object({
    email:yup.string().email({email:"Invalid Email Address"}).required({email:"This field is Required"}),
    password:yup.string().required().min(5,{pass:"Must be greater than 5 char"}),
    name:yup.string().required({name:"This field is Required"}),
    company_name:yup.string().required({compName:"This field is Required"}),
})

export const updatePassword = yup.object({
    newPassword:yup.string().required().min(5,{passNew:"Must be greater than 5 char"}),
    confPassword:yup.string().required().min(5,{passConf:"Must be greater than 5 char"}),
})