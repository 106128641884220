import axios from 'axios'
import React, { useState,useContext} from 'react'
import AgentHeader from "../partialpages/AgentHeader"
import style from './AgentPasswordReset.module.css'
import {useNavigate} from "react-router-dom"
import {LoginStatus} from "../../App"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AGENT_FORGOT_PASSWORD_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Footer from '../partialpages/Footer'
import {agentForgotPassword} from "../../Validations/agentValidations"

function AgentPasswordReset() {
    const [currentErrors, setCurrentErrors] = useState([])
    const navigate  = useNavigate()
    const [isLoading,setIsLoading] = useState(false)
    const [resetEmail,setResetEmail] = useState("")
    const isLogin = useContext(LoginStatus)
    const resetEmailHandler = (e) =>{
        setResetEmail(e.target.value)
    }

    const resetPasswordHandle = (e) =>{
        e.preventDefault()
        const data = {
            email:resetEmail
        }

        agentForgotPassword.validate(data,{ abortEarly: false })
        .then((res) =>{
            setIsLoading(true)
            const url = AGENT_FORGOT_PASSWORD_API
            const config = {
                headers: { 
                    'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                    'Content-Type': 'application/json'
                }
            }
            axios.post(url,data,config)
            .then(
                (response) =>{
                    const result = JSON.parse(response.data)
                    if(result.code === 1){
                        setIsLoading(false)
                        isLogin.setIsLogin(false)
                        navigate(`/agent_login?msg=${result.message}&flag=success`)
                    }else{
                        setIsLoading(false)
                        isLogin.setIsLogin(false)
                        setResetEmail("")
                        toast.error(result.message,{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                        navigate("/agent_passwordreset") 
                    }
                }
            ).catch((error) =>{
                setIsLoading(false)
                toast.error(error.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            })
            setCurrentErrors([])
        }).catch((err) =>{
            setCurrentErrors(err.errors)
        })
    }

  return (
    <>
        <AgentHeader />
        <div className={style.container}>
            <div className={style.wrapper}>
                <form>
                    <div className={style.formContainer}>
                        <div className={style.resetPass}>
                            <h1>Reset password</h1>
                            <p>Enter the email address associated with your account to reset your password.</p>
                        </div>
                        <div className={style.inputReset}>
                            <div className={style.inputResetField}>
                                <div>
                                    <input type="text" value={resetEmail} required onChange={resetEmailHandler}/>
                                    <label>Email address</label>
                                </div>
                                    {
                                      currentErrors.map((e,index) =>(
                                        <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"5px",
                                        }} key={index}>{e.email}</div>
                                    ))
                                    }
                            </div>
                        </div>
                        <div className={style.resetBtn}>
                            <button className={`${isLoading && style.disableBtn}`} onClick={resetPasswordHandle} type='submit'>Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
            
        }
        <ToastContainer />
    </>
  )
}

export default AgentPasswordReset