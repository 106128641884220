import React,{useState} from 'react'
import style from "./SignUp.module.css"
import UserHeader from "../partialpages/UserHeader"
import {Link ,useNavigate}from "react-router-dom"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {REGISTER_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Footer from '../partialpages/Footer'
import {userSignUp} from "../../Validations/userValidations"

function SignUp() {
    const [passwordType,setPasswordType] = useState("password")
    const [currentErrors, setCurrentErrors] = useState([]);
    const navigate = useNavigate()
    const [isLoading,setIsLoading] = useState(false)
    const [emailSignup,setEmailSignup] = useState("")
    const [passwordSignup,setPasswordSignup] = useState("")
    const emailSignupHandler = (e) => {
        setEmailSignup(e.target.value)
    }

    const passwordSignupHandler = (e) => {
        setPasswordSignup(e.target.value)
    }

    const registerUser = (e) =>{
        e.preventDefault()
        const data = {
            email:emailSignup,
            password:passwordSignup
        }

        userSignUp.validate(data,{ abortEarly: false })
        .then((res) =>{
            setIsLoading(true)
            const url = REGISTER_API
            const config = {
                headers:{
                    "secret_key":"K9J920XCVK5B609d263bf3d777c31c234ced",
                    "Content-Type":"application/json"
                }
            }
           
            axios.post(url,data,config)
            .then(
                (response) =>{
                    const result = JSON.parse(response.data)
                    if(result.code === 1){
                        setIsLoading(false)
                        setEmailSignup("")
                        setPasswordSignup("")
                        navigate(`/?msg=${result.message}&flag=success`)
                    }else{
                        setIsLoading(false)
                        toast.error(result.message,{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                        setEmailSignup("")
                        setPasswordSignup("")
                        navigate("/signup") 
                    }
                }
            ).catch((error) =>{
                setIsLoading(false)
                toast.error(error.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            })
         
            setCurrentErrors([])
        }).catch((err) =>{
            setCurrentErrors(err.errors)
        })
    }
    const showHide = () =>{
        if(passwordType === "password"){
            setPasswordType("text")
        }else{
            setPasswordType("password")
        }
    }
  return (
    <>
        <UserHeader />
        <div className={style.container}>
            <div className={style.wrapper}>
                <form>
                    <div className={style.formContainer}>
                        <div className={style.msgSignUp}>
                            <h1>Get started with <br></br> Stack Immigration</h1>
                            <p>One login for all Immigration Services</p>
                        </div>
                        <div className={style.inputSignupContainer}>
                            <div className={style.inputSignupField}>
                                <div>
                                    <div>
                                        <input type="text" required value={emailSignup} onChange={emailSignupHandler}/>
                                        <label>Email address</label>
                                    </div>
                                    {
                                      currentErrors.map((e,index) =>(
                                        <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"5px",
                                        }}  key={index}>{e.email}</div>
                                    ))
                                    }
                                </div>
                                <div>
                                    <div>
                                        <input type={passwordType} required value={passwordSignup} onChange={passwordSignupHandler}/>
                                        <label>Password</label>
                                    </div>
                                        <div className={style.hidePassword}>
                                            {
                                                passwordType === "password" ? <i onClick={showHide} className="fa-solid fa-eye-slash"></i> 
                                                :
                                                <i onClick={showHide}  className="fa-solid fa-eye"></i>
                                            }
                                        </div>
                                    {
                                       currentErrors.map((e,index) =>(
                                        <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"5px",
                                        }} key={index}>{e.pass}</div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.signUpBtn}>
                            <button onClick={registerUser} className={`${isLoading && style.disableBtn}`} type='submit'>Sign up</button>
                        </div>
                        <div className={style.haveAcc}>
                            <span>Already have an account ? <Link to='/'>Login</Link></span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
            
        }
        <ToastContainer />
    </>
  )
}

export default SignUp