import React, { useState } from "react"; 
import { loadStripe } from "@stripe/stripe-js"; 
import { 
  CardElement, 
  Elements, 
  useElements, 
  useStripe 
} from "@stripe/react-stripe-js"; 
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./styles.css"; 
import UserHeader from "../components/partialpages/UserHeader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MAKE_PAYMENT_API} from "../API/index"
import Footer from "../components/partialpages/Footer";
import { Base64 } from "../EncryptDecrypt";


const CARD_OPTIONS = { 
  hidePostalCode: true, 
  iconStyle: "solid", 
  style: { 
    base: { 
      iconColor: "#c4f0ff", 
      fontWeight: 700, 
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif", 
      fontSize: "16px", 
      fontSmoothing: "antialiased", 
      border: "2px solid black"
    }, 
    invalid: { 
      iconColor: "#ffc7ee", 
      color: "#ffc7ee" 
    } 
  } 
}; 

 const CardField = ({ onChange }) => ( 
   <div className="FormRow"> 
    <CardElement options={CARD_OPTIONS} onChange={onChange} /> 
  </div> 

); 

 
const Field = ({ 
  label, 
  id, 
  type, 
  placeholder, 
  required, 
  autoComplete, 
  value, 
  onChange 
}) => ( 
  <div className="FormRow"> 
    <label htmlFor={id}> 
      {label} 
    </label> 
    <input  
      id={id} 
      type={type} 
      placeholder={placeholder} 
      required={required} 
      autoComplete={autoComplete} 
      value={value} 
      onChange={onChange} 
    /> 
  </div> 
) 

 

const SubmitButton = ({ processing, error, children, disabled }) => ( 
  <button 
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`} 
    type="submit" 
    disabled={processing || disabled} 
  > 
    {processing ? "Processing..." : children} 
  </button> 

) 

const ErrorMessage = ({ children }) => ( 
  <div className="ErrorMessage" role="alert"> 
    <svg width="16" height="16" viewBox="0 0 17 17"> 
      <path 
        fill="#FFF" 
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z" 
      /> 
      <path 
        fill="#6772e5" 
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z" 
      /> 
    </svg> 
    {children} 
  </div> 
) 

const CheckoutForm = () => { 
  const [parameters] = useSearchParams()
  const stripe = useStripe(); 
  const elements = useElements(); 
  const [error, setError] = useState(null); 
  const [cardComplete, setCardComplete] = useState(false); 
  const [processing, setProcessing] = useState(false); 
  const [paymentMethod, setPaymentMethod] = useState(null); 

  const [billingDetails, setBillingDetails] = useState({ 
    email: "", 
    phone: "", 
    name: "" 
  })

  const handleSubmit = async (event) => { 
    event.preventDefault(); 
    if (!stripe || !elements) { 
      return; 
    } 
    if (error) { 
      elements.getElement("card").focus(); 
      return; 
    } 

    if (cardComplete) { 
      setProcessing(true) 
    } 

    stripe.createToken(elements.getElement(CardElement))
    .then((token) => { 

      const urlEncode = Base64.decode(parameters.get("code"))
      let arr = urlEncode.split("&")
      let dataEncode = {}
      arr.forEach(element => {
        dataEncode[element.split("=")[0]]= element.split("=")[1]
      })

      const url = MAKE_PAYMENT_API
      const user_token = sessionStorage.getItem("userToken")
      const config = {
        headers:{
          "token":user_token,
          'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
          "Content-Type":"application/json"
        }
      }

      const data ={
        file_id:dataEncode.tid,
        amount:dataEncode.amount,
        stripe_email:billingDetails.email,
        stripe_token:token.token.id,
        stripe_name:billingDetails.name
      }

      axios.post(url,data,config)
      .then((response) =>{
        const result = JSON.parse(response.data)
        if(result.code === 1){
          setPaymentMethod(true); 
          setProcessing(false)
          toast.success(result.message,{
            position:"top-right",
            autoClose: 5000,
            closeOnClick: true,
        })
        }else{
          setPaymentMethod(false); 
          setProcessing(false)
          toast.error(result.message,{
            position:"top-right",
            autoClose: 5000,
            closeOnClick: true,
        })
        }
      }).catch((error) =>{
        setPaymentMethod(false); 
        setProcessing(false)
        console.log(error.message)
      })

    })
  }

  const navigate = useNavigate()

  const backToHomeHandler = () =>{
    navigate("/my_applications")
  }

  const urlEncode = Base64.decode(parameters.get("code"))
  let arr = urlEncode.split("&")
  let dataEncode = {}
  arr.forEach(element => {
    dataEncode[element.split("=")[0]]= element.split("=")[1]
  })
  return paymentMethod ? ( 
    <div className="Result"> 
      <div className="companyHeader">
        <div className="logo">
            <img src="https://tiffinhub.ca/assets/images/StackImmigration_Logo.png" alt="logo" />
        </div>
        <h1 className="name">Stack Immigration</h1>
      </div>
      <div className="ResultTitle" role="alert"> 
        <h2>Payment successful </h2>
      </div> 
      <div className="ResultMessage"> 
        <h4>Thank you ! We received your payment successfully</h4>
      </div> 
      <div className="back-to-app">
        <button onClick={backToHomeHandler}>Back to Application</button>
      </div>
    </div> 
  ) 
  : 
  ( 
    <div className="checkContainerWrapper">
    <div className="companyHeader">
        <div className="logo">
            <img src="https://tiffinhub.ca/assets/images/StackImmigration_Logo.png" alt="logo" />
        </div>
        <h1 className="name">Stack Immigration</h1>
    </div>
    <div className="productInfo">
      <div className="application">
          <div className="imgContainer">
              <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
          </div>
          <div className="infoApplication">
              <h1>{dataEncode.appName}</h1>
              <span>{dataEncode.description}</span>
          </div>
          <div className="status">
              <h3>Price</h3>
              <span style={{fontWeight:"700"}}>${dataEncode.amount}</span>
          </div>
      </div>
    </div>
        <form className="form" onSubmit={handleSubmit}> 
          <fieldset className="FormGroup"> 
            <Field 
              className="inputField"
              label="Name" 
              id="name" 
              type="text" 
              placeholder="Card Holder Name" 
              required 
              autoComplete="name" 
              value={billingDetails.name} 
              onChange={(e) => { 
                setBillingDetails({ ...billingDetails, name: e.target.value }); 
              }} 
            /> 
    
            <Field 
              className="inputField"
              label="Email" 
              id="email" 
              type="email" 
              placeholder="Enter your Email" 
              required 
              autoComplete="email" 
              value={billingDetails.email} 
              onChange={(e) => { 
                setBillingDetails({ ...billingDetails, email: e.target.value }); 
              }} 
            /> 
    
            <Field 
              className="inputField"
              label="Phone" 
              id="phone" 
              type="tel" 
              placeholder="Enter Mobile No." 
              required 
              autoComplete="tel" 
              value={billingDetails.phone} 
              onChange={(e) => { 
                setBillingDetails({ ...billingDetails, phone: e.target.value }); 
              }} 
            /> 
          </fieldset> 
          <fieldset className="FormGroup"> 
            <CardField 
              onChange={(e) => { 
                setError(e.error); 
                setCardComplete(e.complete); 
              }} 
            /> 
          </fieldset> 
          {error && <ErrorMessage>{error.message}</ErrorMessage>} 
          <SubmitButton processing={processing} error={error} disabled={!stripe}> 
            Pay ${dataEncode.amount}
          </SubmitButton> 
        </form> 
  </div>
  )
}

const ELEMENTS_OPTIONS = { 
  fonts: [ 
    { 
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto" 
    } 
  ] 
}; 
 
const stripePromise = loadStripe("pk_test_51LcL2MFAqEW2d5qhSoMWqnU2f1OFeQeIiwbTtmNimFa8RHQaDUuNb3Pzm1Kk2nir1cXzQOpmbiJ0hyOHEZRYoOP700IXZrge5G"); 

const AppCheck = () => { 
  return ( <>
    <UserHeader />
    <div className="AppWrapper"> 
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}> 
        <CheckoutForm /> 
      </Elements> 
    </div> 
    <Footer />
    <ToastContainer />
  </>
  ); 
}; 

export default AppCheck; 
