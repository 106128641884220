import React, { useContext, useEffect } from 'react'
import{useNavigate,useParams} from "react-router-dom"
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import { LoginStatus } from '../../App';
import {VERIFY_EMAIL_API} from "../../API/index"

function EmailVerify() {
    const navigate = useNavigate()
    const isLogin = useContext(LoginStatus)
    const {id} = useParams()

    useEffect(()=>{
        const verifyEmail = () =>{
            const url = VERIFY_EMAIL_API
            const config = {
                headers:{
                    "secret_key":"K9J920XCVK5B609d263bf3d777c31c234ced",
                    "Content-Type":"application/json",
                }
            }
            const data = {
                id:id
            }
            axios.post(url,data,config)
            .then(
                (response) =>{
                    const result = JSON.parse(response.data)
                    if(result.code === 1){    
                        isLogin.setIsLogin(false)
                        navigate(`/?msg=${result.message}&flag=success`)
                    }else{
                        isLogin.setIsLogin(false)
                        navigate(`/?msg=${result.message}&flag=fail`)
                    }
                }
            ).catch((error) =>{
                console.log(error.message)
            })
        }
        verifyEmail()
    },[])

  return (
    <></>
  )
}

export default EmailVerify