import React, { useContext, useState } from 'react'
import UserHeader from '../partialpages/UserHeader'
import style from "./UpdatePassword.module.css"
import {useParams,useNavigate} from "react-router-dom"
import { LoginStatus } from '../../App'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UPDATE_FORGOT_PASSWORD_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Footer from '../partialpages/Footer'
import {updatePassword} from "../../Validations/userValidations"

function UpdatePassword() {

    const [passwordType,setPasswordType] = useState({
        newPass:"password",
        confPass:"password"
    })
    const [currentErrors, setCurrentErrors] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [confirmPassword,setConfirmPassword] = useState("")
    const [newPassword,setNewPassword] = useState("")
    const navigate = useNavigate()
    const {id} = useParams()
    const isLogin = useContext(LoginStatus)

    const confirmPasswordHandler = (e) =>{
        setConfirmPassword(e.target.value)
    }
    const newPasswordHandler = (e) =>{
        setNewPassword(e.target.value)
    }


    const updatePasswordHandle = (e) =>{
        e.preventDefault()
        const validData = {
            newPassword:newPassword,
            confPassword:confirmPassword
        }
        updatePassword.validate(validData,{ abortEarly: false })
        .then((res) =>{   
            if(newPassword !== confirmPassword){
                alert("Password is note match")
            }else{
                setIsLoading(true)
                const url = UPDATE_FORGOT_PASSWORD_API
                const config = {
                    headers: { 
                        'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                        'Content-Type': 'application/json'
                      },
                }
                const data = {
                    id:id,
                    password:newPassword
                }
                axios.post(url,data,config)
                .then(
                    (response) =>{
                        const result = JSON.parse(response.data)
                        if(result.code === 1){
                            setIsLoading(false)
                            isLogin.setIsLogin(false)
                            setNewPassword("")
                            setConfirmPassword("")
                            navigate(`/?msg=${result.message}&flag=success`)
                        }else{
                            setIsLoading(false)
                            isLogin.setIsLogin(false)
                            setNewPassword("")
                            setConfirmPassword("")
                            toast.error(result.message,{
                                position:"top-right",
                                autoClose: 1000,
                                closeOnClick: true,
                            })
                            navigate(`/forgot_password/${id}`)
                        }
                    }
                ).catch((error) =>{
                    setIsLoading(false)
                    toast.error(error.message,{
                        position:"top-right",
                        autoClose: 1000,
                        closeOnClick: true,
                    })
                })
            }   
            setCurrentErrors([])
        }).catch((err) =>{
            setCurrentErrors(err.errors)
        })
    }

    const newShowHide = () =>{
        if(passwordType.newPass === "password"){
            setPasswordType({
                ...passwordType,
                newPass:"text"
            })
        }else{
            setPasswordType({
                ...passwordType,
                newPass:"password"
            })
        }
    }
    const confShowHide = () =>{
        if(passwordType.confPass === "password"){
            setPasswordType({
                ...passwordType,
                confPass:"text"
            })
        }else{
            setPasswordType({
                ...passwordType,
                confPass:"password"
            })
        }
    }

  return (
    <>
        <UserHeader />
        <div className={style.container}>
            <div className={style.wrapper}>
                <form>
                    <div className={style.formContainer}>
                        <div className={style.resetPass}>
                            <h1>Update password</h1>
                        </div>
                        <div className={style.inputReset}>
                            <div className={style.inputResetField}>
                                <div>
                                    <div>
                                        <input type={passwordType.newPass} value={newPassword} required onChange={newPasswordHandler}/>
                                        <label>New Password</label>
                                    </div>
                                    <div className={style.hidePassword}>
                                            {
                                                passwordType.newPass === "password" ? <i onClick={newShowHide} className="fa-solid fa-eye-slash"></i> 
                                                :
                                                <i onClick={newShowHide}  className="fa-solid fa-eye"></i>
                                            }
                                        </div>
                                    {currentErrors.map((e,index) =>(
                                        <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"3px",
                                        }}  key={index}>{e.passNew}</div>
                                    ))
                                    }
                                </div>
                                <div>
                                    <div>
                                        <input type={passwordType.confPass} value={confirmPassword} required onChange={confirmPasswordHandler}/>
                                        <label>Confirm Password</label>
                                    </div>
                                    <div className={style.hidePassword}>
                                            {
                                                passwordType.confPass === "password" ? <i onClick={confShowHide} className="fa-solid fa-eye-slash"></i> 
                                                :
                                                <i onClick={confShowHide}  className="fa-solid fa-eye"></i>
                                            }
                                        </div>
                                    {currentErrors.map((e,index) =>(
                                        <div style={{
                                            fontSize:"0.7rem",
                                            fontWeight:"700",
                                            color:"red",
                                            marginTop:"3px",
                                        }}  key={index}>{e.passConf}</div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.resetBtn}>
                            <button className={`${isLoading && style.disableBtn}`} onClick={updatePasswordHandle} type='submit'>Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
            
        }
        <ToastContainer />
    </>
  )
}

export default UpdatePassword