import React,{useEffect} from 'react'
import AgentHeader from '../partialpages/AgentHeader'
import {useSearchParams,useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import style from './AgentHome.module.css';
import Footer from '../partialpages/Footer';


function AgentHome() {
  const [queryParams,setQueryParams] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() =>{
      if(queryParams.get("flag") !== null){
          toast.success(queryParams.get("msg"),{
              position:"top-right",
              autoClose: 1000,
              closeOnClick: true,
          })
          setTimeout(() =>{
              queryParams.delete("flag")
              setQueryParams(queryParams)
          },500)
      }       
  },[])

  const myFilesNavigate = () =>{
    navigate("/my_files")
  }

  return (
    <>
        <AgentHeader />
        <div className={style.agentContainer}>
            <h1>Welcome Back </h1>
            <div className={style.myFilesBtn}>
                <button onClick={myFilesNavigate}>My Files</button>
            </div>
        </div>
        <Footer />
        <ToastContainer />
    </>
  )
}

export default AgentHome