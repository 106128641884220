import React from 'react'
import style from "./Footer.module.css"
import {Link} from "react-router-dom"

function Footer() {
  return (
    <div className={style.footContainer}>
        <span className={style.privacyMsg}>We’ll never share your details. See our <Link to="#">Privacy Policy</Link></span>
        <span className={style.copyRight}>©2022 All Rights are Reserved by Stack Immigration</span>
    </div>
  )
}

export default Footer