import React, { useEffect, useState } from 'react'
import style from "./AgentApplications.module.css"
import AgentHeader from "../partialpages/AgentHeader"
import axios from 'axios'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {AGENT_FILES_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Footer from '../partialpages/Footer'
import {useNavigate, useParams} from "react-router-dom"


function AgentApplications() {

    const navigate = useNavigate()
    const {id} = useParams()
    const [applicationLoading,setApplicationLoading] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [userInfo,setUserInfo] = useState({})
    const [status,setStatus] = useState(false)

    useEffect(() =>{
        const getFiles = () =>{
            setIsLoading(true)
            const url = AGENT_FILES_API
            const token = sessionStorage.getItem("agentToken")
            const config = {
                headers:{
                    "token":token,
                    'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                    "Content-Type": "application/json",
                }
            }
            axios.post(url,{},config)
            .then(
                (response) =>{
                    const result = JSON.parse(response.data)
                    setUserInfo(result)
                    if(result.code === 1){
                        setUserInfo(result)
                        setStatus(true)
                        setIsLoading(false)
                        setApplicationLoading(true)
                        toast.success(result.message,{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                    }else{
                        setStatus(false)
                        setApplicationLoading(true)
                        setIsLoading(false)
                        toast.error("No Files Found",{
                            position:"top-right",
                            autoClose: 1000,
                            closeOnClick: true,
                        })
                    }
                }
            ).catch((error) =>{
                setIsLoading(false)
                toast.error(error.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            })
        }
        getFiles()
    },[])
    const applicationInfoHandler =  (id) =>{
        navigate("/agent_app_info/"+id)
    }
  return (
    <>
        <AgentHeader />
        <div className={style.homeContainer}>
            <div className={style.homeWrapper}>
                <div className={style.homeMessage}>
                    <h1>My Assigned Files</h1>
                </div>
                <div className={style.homeOptions}>
                    {
                        applicationLoading ? <>
                        {
                        status ? <>
                            {
                            userInfo.data.info.count > 0 ?
                            <>
                            {
                                userInfo.data.data.map((item,index) =>(
                                    <div className={style.application} key={index} onClick={() => applicationInfoHandler(item.id)}>
                                        <div className={style.imgContainer}>
                                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                                        </div>
                                        <div className={style.infoApplication}>
                                            <h1>{item.Application_Type}</h1>
                                            <span>To Know more about {item.Application_Type}</span>
                                        </div>
                                        <div className={style.status}>
                                            <h3>Status</h3>
                                            <span>{item.Status}</span>
                                        </div>
                                    </div>
                                ))
                            }
                            </>
                                :
                            <div>
                                <h1>You have not registered yet</h1>
                            </div>
                            }   
                            </>
                        :
                        <div style={{
                            width:"100%",
                            height:"inherit",
                            display:"flex",
                            flexDirection:"column",
                            gap:"1rem",
                            justifyContent:"center",
                            alignItems:"center"
                        }}>
                            <h1 style={{
                                width:"100%",
                                textAlign:"center",
                            }}>No Files Found...</h1>
                        </div>
                    }
                        
                        </>
                        :
                        <>
                        <div>
                            <h1 style={{
                                width:"100%",
                                textAlign:"center"                                        
                            }}>Files Loading...</h1>
                        </div>
                        </>
                    }
                </div>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
        }
        <ToastContainer />
    </>
  )
}

export default  AgentApplications