import React, { useState,useContext, useEffect } from 'react'
import style from "./ContactUs.module.css"
import UserHeader from "../partialpages/UserHeader"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate,Link} from "react-router-dom"
import {CONTACT_US_API} from "../../API/index"
import Loader from '../Utilities/Loader';
import AgentHeader from '../partialpages/AgentHeader';
import Footer from '../partialpages/Footer';
import {contactus} from "../../Validations/userValidations"

function ContactUs() {

  const [currentErrors, setCurrentErrors] = useState([])
  const [isPopUp,setIsPopUp] = useState(false)
  const [isAgent,setIsAgent] = useState(false)
  const navigate = useNavigate()
  const [nameContact,setNameContact] = useState("")
  const [messageContact,setMessageContact] = useState("")
  const [isLoading,setIsLoading] = useState(false)
  
  let Email = ""
  const [emailContact,setEmailContact] = useState(Email)

  useEffect(() =>{
    if(sessionStorage.getItem("userToken") !== null){
      setEmailContact(sessionStorage.getItem("userEmail"))
      setIsAgent(false)
    }else if(sessionStorage.getItem("agentToken") !== null){
      setEmailContact(sessionStorage.getItem("agentEmail"))
      setIsAgent(true)
    }else{
      setEmailContact("")
      setIsAgent(false)
    }
  },[])
 
  const emailHandler = (e) =>{
    setEmailContact(e.target.value)
  }
  const nameHandler = (e) =>{
    setNameContact(e.target.value)
  }
  const messageHandle = (e) =>{
    setMessageContact(e.target.value)
  }
  const submitHandler = (e) =>{
    e.preventDefault()
    const data = {
      email:emailContact,
      name:nameContact,
      message:messageContact
    }
    contactus.validate(data,{ abortEarly: false })
    .then((res) =>{    
      setIsLoading(true)
      const url = CONTACT_US_API
      const config = {
          headers:{
            'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
            "Content-Type": "application/json",
        }
      } 
  
      axios.post(url,data,config)
      .then(
          (response) =>{
              const result = JSON.parse(response.data)
              if(result.code === 1){
                  setIsLoading(false)
                  setEmailContact("")
                  setMessageContact("")
                  setNameContact("")
                  toast.success(result.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
                setIsPopUp(true)
                navigate("/contactus")
              }else{
                  setIsLoading(false)
                  setEmailContact("")
                  setMessageContact("")
                  setNameContact("")
                  toast.error(result.message,{
                      position:"top-right",
                      autoClose: 1000,
                      closeOnClick: true,
                  })
                navigate("/contactus")
              }   
          }
      ).catch((error) =>{
        setIsLoading(false)
        toast.error(error.message,{
            position:"top-right",
            autoClose: 1000,
            closeOnClick: true,
        })
      })
      setCurrentErrors([])
    }).catch((err) =>{
        console.log(err.errors)
        setCurrentErrors(err.errors)
    })
  }
  const stayHandler = () =>{
    setIsPopUp(false)
  }
  return (
    <> 
      
      {
        isAgent ? <AgentHeader /> : <UserHeader />
      }
      <div className={style.contactContainer}>
          <div className={style.contactWrapper}>
            <h1>Contact Us</h1>
            <p style={{
                  textAlign: "center",
                  fontWeight: "700",
                  marginTop: "0.5rem",
            }}>We are here to help you!</p>
            <form>
              <div className={style.contactData}>
                <div>
                  <div>
                    <input type="text" required value={nameContact} onChange={nameHandler}/>
                    <label>Name</label>
                  </div>
                  {currentErrors.map((e,index) =>(
                      <div style={{
                          fontSize:"0.7rem",
                          fontWeight:"700",
                          color:"red",
                          marginTop:"5px",
                      }}  key={index}>{e.name}</div>
                  ))
                  }
                </div>
                <div>
                  <div>
                    <input type="text" value={emailContact} required onChange={emailHandler} />
                    <label>Email</label>
                  </div>
                  {currentErrors.map((e,index) =>(
                      <div style={{
                          fontSize:"0.7rem",
                          fontWeight:"700",
                          color:"red",
                          marginTop:"5px",
                      }}  key={index}>{e.email}</div>
                  ))
                  }
                </div>
                <div className={style.msg}>
                  <div>
                    <textarea onChange={messageHandle} required value={messageContact}></textarea>
                    <label>Type your message</label>
                  </div>
                  {currentErrors.map((e,index) =>(
                      <div style={{
                          fontSize:"0.7rem",
                          fontWeight:"700",
                          color:"red",
                          marginTop:"5px",
                      }}  key={index}>{e.msg}</div>
                  ))
                  }
                </div>
                <div className={style.enquiry}>
                  <button className={`${isLoading && style.disableBtn}`} onClick={submitHandler}>Send Enquiry</button>
                </div>
              </div>
            </form>
          </div>
      </div>
      <Footer />

        {
          isLoading && <Loader />
        }

        {
          isPopUp && 
          <>

            <div className={style.popupContainer}>
              <div className={style.popupWrapper}>
                  <div className={style.popUpMsg}>
                      <h3>We will contact you soon !</h3>
                  </div>
                  <div className={style.popupBtns}>
                      <Link to="/home"> Home </Link>
                      <Link onClick={stayHandler} to="/contactus"> stay </Link>
                  </div>
              </div>
            </div>
          </>
        }

      <ToastContainer />
    </>
  )
}

export default ContactUs