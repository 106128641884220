import React, { useContext, useEffect, useState } from 'react'
import style from './AgentHeader.module.css'
import {Link} from "react-router-dom"
import {LoginStatus} from "../../App"
import {useNavigate,useLocation} from "react-router-dom"

function AgentHeader() {
  
  const isLogin = useContext(LoginStatus)
  const navigate = useNavigate()
  const loc = useLocation()
  const [showNav,setShowNav] = useState(false)
  useEffect(()=>{
    let val = sessionStorage.getItem("agentToken")
    if(val !== null){
      isLogin.setIsLogin(true)
      navigate(loc)
    }else{
      if(loc.pathname === "/agent_form"){
        navigate("/agent_form")
      }else if(loc.pathname === "/agent_passwordreset"){
        navigate("/agent_passwordreset")
      }else if(loc.pathname === "/contactus"){
        navigate("/contactus")
      }else{
        navigate("/agent_login")
      }
    }
  },[])

  const logOutHandler = () =>{
    isLogin.setIsLogin(false)
    sessionStorage.clear()
    navigate("/agent_login")
  }

  const iconHandler = () =>{
    setShowNav(true)
  }
  const closeHandler = () =>{
    setShowNav(false)
  }
  return (
    <div className={style.navbar}>
        <div className={style.logoContainer}>
          {
            isLogin.isLogin ? 
          <div className={style.logoImg}>
            <Link to='/home'>
              <img src='https://tiffinhub.ca/assets/images/StackImmigration_Logo.png' alt='logo' />
            </Link>
          </div>
          :
          <div className={style.logoImg}>
            <Link to="/">
              <img src='https://tiffinhub.ca/assets/images/StackImmigration_Logo.png' alt='logo' />
            </Link>
          </div>
          }
          <Link to='#' className={style.logoBtn}> Stack Immigration </Link>
        </div>
        <div className={style.desktop}>
          <div className={style.desktopWrapper}>
              <div className={style.buttonContainer}>
                {isLogin.isLogin && 
                  <div className={style.bookNoW}>
                    <Link to="/agent_home">Home</Link>
                  </div>
                }
                {isLogin.isLogin && 
                  <div className={style.bookNoW}>
                    <Link to="/my_files">My Files</Link>
                  </div>
                }
                {isLogin.isLogin ? undefined :
                <div style={{position:"relative"}}>
                  <div className={style.dropdown}>
                    <button className={style.dropBtn}>Login &nbsp;
                      <i className="fa-solid fa-chevron-down"></i>
                    </button>
                    <div className={style.dropdownContent}>
                      <Link to="/">User Login</Link>
                      <Link to="/agent_login">Agent Login</Link>
                    </div>
                  </div> 
                  </div>
                }
                {isLogin.isLogin ? undefined :
                  <div className={style.bookNoW}>
                    <Link to="/agent_form">Become Partner</Link>
                  </div>
                }
               
                  <div className={style.contactBtn}>
                    <Link to="/contactus">Contact Us</Link>
                  </div>
            
              </div>
              {isLogin.isLogin && 
              <div className={style.logOutBtn}>
                <button onClick={logOutHandler}>Logout</button>
              </div>
              }
          </div>
        </div>
        <div className={style.loginDesktopHamBurger}>
          <div className={style.loginDesktopHamBurgerWrapper}>
            <div className={`${style.hamburgerMenu} ${showNav ? style.showMenu : style.hideMenu}`}>
              {isLogin.isLogin && 
                <div className={style.bookNoW}>
                  <Link to="/agent_home">Home</Link>
                </div>
              }
              {isLogin.isLogin && 
                <div className={style.bookNoW}>
                  <Link to="/my_files">My Files</Link> 
                </div>
              }
                 {isLogin.isLogin ? undefined :
                  <div className={style.bookNoW}>
                    <Link to="/agent_form">Become Partner</Link>
                  </div>
                }
        
                <div className={style.contactBtn}>
                  <Link to="/contactus">Contact Us</Link>
                </div>
  
              <div className={style.close}>
                <span onClick={closeHandler}>X</span>
              </div>
            </div>
            {isLogin.isLogin ? undefined :
                <div className={style.desktopLogOut} style={{position:"relative"}}>
                  <div className={style.dropdown}>
                    <button className={style.dropBtn}>Login &nbsp;
                      <i className="fa-solid fa-chevron-down"></i>
                    </button>
                    <div className={style.dropdownContent}>
                      <Link to="/">User Login</Link>
                      <Link to="/agent_login">Agent Login</Link>
                    </div>
                  </div> 
                </div>
              }
            {isLogin.isLogin && 
              <div className={style.logOutBtn}>
                <button onClick={logOutHandler}>Logout</button>
              </div>
            }    
            {
              isLogin.isLogin && 
                <div className={style.icon}>
                  <h3 onClick={iconHandler}><i className="fa-sharp fa-solid fa-bars"></i></h3>
                </div>
            }
          </div>
        </div>
        <div className={style.logOutDesktop}>
          <div className={style.logOutDesktopWrapper}>
            <div className={style.mobileContactLogin}>
                {isLogin.isLogin ? undefined :
                <div style={{position:"relative"}}>
                  <div className={style.dropdown}>
                    <button className={style.dropBtn}>Login &nbsp;
                      <i className="fa-solid fa-chevron-down"></i>
                    </button>
                    <div className={style.dropdownContent}>
                      <Link to="/">User Login</Link>
                      <Link to="/agent_login">Agent Login</Link>
                    </div>
                  </div> 
                  </div>
                }
                {isLogin.isLogin ? undefined :
                  <div className={style.bookNoW}>
                    <Link to="/agent_form">Become Partner</Link>
                  </div>
                }
                {
                  isLogin.isLogin ? undefined:
                  <div className={style.contactBtn}>
                    <Link to="/contactus">Contact Us</Link>
                  </div>
                }
            </div>
          </div>
        </div>
        <div className={style.logOutHamburger}>
            {
              isLogin.isLogin ? undefined :
            <div className={style.icon}>
              <h3 onClick={iconHandler}><i className="fa-sharp fa-solid fa-bars"></i></h3>
            </div>
            }
        </div>
       </div>
  )
}

export default React.memo(AgentHeader)