import axios from 'axios'
import React, { useEffect } from 'react'
import style from "./AgentAppInfo.module.css" 
import { useParams } from 'react-router-dom'
import { GET_FILE_DETAILS_API,UPDATE_FILE_STATUS_API} from '../../API'
import { useState } from 'react'
import Footer from '../partialpages/Footer'
import Loader from '../Utilities/Loader'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AgentHeader from '../partialpages/AgentHeader'

function AgentAppInfo() {
    const [upStatus,setUpStatus] = useState("")
    const {id} = useParams()
    const [fileInfo,setFileInfo] = useState({})
    const [isLoading,setIsLoading] = useState(false)
    const [filesLoading,setFilesLoading] = useState(false)
    const [status,setStatus] = useState(false)

    const getAllData = () =>{
        setIsLoading(true)
        const url = GET_FILE_DETAILS_API
        const token = sessionStorage.getItem("agentToken")
        const config = {
            headers:{
                "token":token,
                'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                "Content-Type": "application/json",
            }
        }
        const data = {
            id:id,
        }
        axios.post(url,data,config)
        .then((response) =>{
            const result = JSON.parse(response.data)
            setFileInfo(result.data.data[0])
            if(result.code === 1){
                setFilesLoading(true)
                setStatus(true)
                setIsLoading(false)
                toast.success(result.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
                setUpStatus(result.data.data[0].Status)
            }else{
                setStatus(false)
                setIsLoading(false)
                setFilesLoading(true)
                toast.error("No File Info Found",{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            }
        }).catch((error) =>{
            console.log(error.message)
        })
    }

    useEffect(() =>{
       getAllData()
    },[])

    const updateAPI = (e,id) =>{
        setIsLoading(true)
        const url = UPDATE_FILE_STATUS_API
        const token = sessionStorage.getItem("agentToken")
        const config = {
            headers:{
                "token":token,
                'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                "Content-Type": "application/json",
            }
        }

        const data = {
            id:id,
            status:e.target.value
        }
        axios.post(url,data,config)
        .then((response) =>{
            getAllData()
        })
    }

  return (
    <>
        <AgentHeader />
        <div className={style.infoContainer}>
            <div className={style.infoWrapper}>
                <h1>My File Information</h1>
                {
                    filesLoading ?
                    <>
                    {
                        status ?
                        <>
                            <div className={style.content}>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Belongs To </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Name}
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Application Type </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Application_Type}
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Application Status </h3>
                                    </div>
                                    <div className={style.value}>
                                        <select className={style.appStatus} value={upStatus} onChange={(e) => updateAPI(e,id)}>
                                            <option value="Approved">Approved</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Rejected" >Rejected</option>
                                            <option value="In Process" >In Process</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Payment </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Transaction_Id === null ? <button  style={{backgroundColor:"red",color:"white"}}>Not Paid</button> : 
                                        <button style={{backgroundColor:"green",color:"white"}}>Paid</button> }
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Registered on </h3>
                                    </div>
                                    <div className={style.value}>
                                        {new Date(fileInfo.Created_Time).toLocaleDateString("en-US",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                    </div>
                                </div>
                                <div>
                                    <a className={style.folder} href={fileInfo.Folder_Url} target="_blank">Access Document Here</a>
                                </div>
                            </div>
                        </>:
                        <>
                            <div style={{
                                width:"100%",
                                height:"inherit",
                                display:"flex",
                                flexDirection:"column",
                                gap:"1rem",
                                justifyContent:"center",
                                alignItems:"center"
                            }}>
                                <h1 style={{
                                    width:"100%",
                                    textAlign:"center",
                                }}>No Application Found</h1>
                            </div>
                        
                        </>
                    }
                    
                    </>
                    :
                    <>
                        <div>
                            <h1 className={style.appLoad}>File Info Loading...</h1>
                        </div>
                    </>
                }
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
        }
        <ToastContainer />
    </>
  )
}

export default AgentAppInfo