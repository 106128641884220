import React,{useState,useRef} from 'react'
import style from './AgentForm.module.css'
import AgentHeader from "../partialpages/AgentHeader"
import FormData from "form-data"
import axios from 'axios'
import { ToastContainer,toast } from 'react-toastify'
import {useNavigate} from "react-router-dom"
import {CREATE_AGENT_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Autocomplete from "react-google-autocomplete"
import Footer from '../partialpages/Footer'
import {becomePartner} from "../../Validations/agentValidations"


function AgentForm() {
  const [aT,setAt] = useState(false)
  const [currentErrors, setCurrentErrors] = useState([])
  const refCheckboxOne = useRef(null)
  const refCheckboxTwo = useRef(null)
  const divingLicense = useRef(null)
  const irccCertificate = useRef(null)

  const files = {
    driving_license: null,
    ircc_certificate: null,
  }

  const myData = {
    email:"",
    name:"",
    company_name:"",
    specialist_in:"Specialist In ?",
    company_address:"",
    password:""
}
  const [dL,setDL] = useState(false)
  const [ircc,setIRCC] = useState(false)
  const navigate = useNavigate()
  const [passwordType,setPasswordType] = useState("password")
  const [isLoading,setIsLoading] = useState(false)
  const [allFiles,setAllFiles] = useState(files)
  const [formData,setFormData] = useState(myData)
  const [address,setAddress] = useState("")
  const [appointment,setAppointment] = useState("N")
  const [notary_tested_license,setNotary] = useState("N")

  const sendData = new FormData()
  

  const drivingLicenseHandler = (e) =>{
    setAllFiles({
      ...allFiles,
      driving_license:e.target.files[0],
    })
  }
  const irccCertificateHandler = (e) =>{
    setAllFiles({
      ...allFiles,
      ircc_certificate:e.target.files[0],
    })
  }

  const appointmentHandler = (e) =>{
    if(e.target.checked){
      setAppointment("Y")
    }else{
      setAppointment("N")
    }
  }

  const notaryHandler = (e) =>{
    if(e.target.checked){
      setNotary("Y")
    }else{
      setNotary("N")
    }
  }

  const formDataHandler = (e) =>{
    const name = e.target.name
    const val = e.target.value
    setFormData(
        {
            ...formData,
            [name]:val,
        }
    )
  }

    const submitHandler = (e) =>{
      e.preventDefault()
      const validData = {
        email:formData.email,
        password:formData.password,
        company_name:formData.company_name,
        name:formData.name
      } 
   
      allFiles.driving_license == null ?  setDL(true) : setDL(false)
      allFiles.ircc_certificate == null ? setIRCC(true) : setIRCC(false)
      formData.specialist_in === "Specialist In ?" ? setAt(true) : setAt(false)

      becomePartner.validate(validData,{ abortEarly: false })
      .then((res) =>{
        if(formData.specialist_in === "Specialist In ?" || allFiles.driving_license === null || allFiles.ircc_certificate === null){
          console.log("pass")
        }else{
          setIsLoading(true)
          for(let key in allFiles){
            sendData.append(key,allFiles[key])
          }
          for(let key in formData){
            sendData.append(key,formData[key])
          }
          const url = CREATE_AGENT_API
          const config = {
            headers:{
              'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
              "Content-Type": "multipart/form-data",
            }
          }
    
          const data = {
            ...formData,
            ...allFiles,
            company_address:address,
            appointment:appointment,
            notary_tested_license:notary_tested_license
          }
    
          axios.post(url,data,config)
          .then(
            (response) =>{
              const result = JSON.parse(response.data)
              if(result.code === 1){
                setIsLoading(false)
                setFormData(
                  {
                    email:"",
                    name:"",
                    company_name:"",
                    specialist_in:"Specialist In ?",
                    password:""
                  }
                )
                setAddress("")
                divingLicense.current.value = null
                irccCertificate.current.value = null
                refCheckboxOne.current.checked = false
                refCheckboxTwo.current.checked = false
                toast.success(result.message,{
                  position:"top-right",
                  autoClose: 1000,
                  closeOnClick: true,
                })
                  navigate(`/agent_login?msg=${result.message}&flag=success`)
              }else{
                setIsLoading(false)
                setFormData(
                  {
                    email:"",
                    name:"",
                    company_name:"",
                    specialist_in:"Specialist In ?",
                    password:""
                  }
                )
                setAddress("")
                divingLicense.current.value = null
                irccCertificate.current.value = null
                refCheckboxOne.current.checked = false
                refCheckboxTwo.current.checked = false
                toast.error(result.message,{
                  position:"top-right",
                  autoClose: 1000,
                  closeOnClick: true,
                })
                navigate("/agent_form")
              }
            }
          ).catch((error) =>{
            setIsLoading(false)
            toast.error(error.message,{
                position:"top-right",
                autoClose: 1000,
                closeOnClick: true,
            })
          })
          setCurrentErrors([])
        }    
      }).catch((err) =>{
          setCurrentErrors(err.errors)
      })
     
    }

    const showHide = () =>{
      if(passwordType === "password"){
          setPasswordType("text")
      }else{
          setPasswordType("password")
      }
  }
  return (
    <>
      <AgentHeader />
      <div className={style.agentContainer}>
        <div className={style.agentWrapper}>
          <div className={style.agentMessage}>
            <h1>Become Partner</h1>
            <p>Join with our organization</p>
          </div>
          <form>
            <div className={style.agentFormData}>
              <div className={style.agentLeft}>
                  <div>
                    <div>
                        <input type="text" value={formData.email} name="email" required onChange={formDataHandler}/>
                        <label>Email address</label>
                    </div>
                    {currentErrors.map((e,index) =>(
                        <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",
                        }}  key={index}>{e.email}</div>
                    ))
                    }
                  </div>
                  <div>
                    <div>
                      <input type={passwordType} value={formData.password} name="password" required onChange={formDataHandler}/>
                      <label>Password</label>
                    </div>
                        <div className={style.hidePassword}>
                          {
                              passwordType === "password" ? <i onClick={showHide} className="fa-solid fa-eye-slash"></i> 
                              :
                              <i onClick={showHide}  className="fa-solid fa-eye"></i>
                          }
                      </div>
                          {currentErrors.map((e,index) =>(
                            <div style={{
                                fontSize:"0.7rem",
                                fontWeight:"700",
                                color:"red",
                                marginTop:"3px",
                            }}  key={index}>{e.pass}</div>
                        ))
                        }
                  </div>
                  <div>
                    <div>
                      <input type="text" value={formData.name} name="name" required onChange={formDataHandler}/>
                      <label>Full Name</label>
                    </div>
                    {currentErrors.map((e,index) =>(
                        <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",
                        }}  key={index}>{e.name}</div>
                    ))
                    }
                  </div>
                  <div>
                    <div>
                        <input type="text" value={formData.company_name} name="company_name" required onChange={formDataHandler}/>
                          <label>Company Name</label>
                    </div>
                    {currentErrors.map((e,index) =>(
                        <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",
                        }}  key={index}>{e.compName}</div>
                    ))
                    }
                  </div>
              </div>
              <div className={style.agentRight}>
                  <div>
                    <div>
                      <select onChange={formDataHandler} name="specialist_in" value ={formData.specialist_in}>
                          <option selected disabled>Specialist In ?</option>
                          <option value="TR to PR">TR to PR</option>
                          <option value="Permanent Residence">Permanent Residence</option>
                          <option value="Study VISA">Study VISA</option>
                          <option value="Work VISA">Work VISA</option>
                          <option value="Visitor VISA">Visitor VISA</option>
                      </select>
                    </div>
                    {
                        aT && <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",}}>
                                Please select one option
                        </div>
                        }
                  </div>
                  <div className={style.file}>
                      <label> Upload the Driving License </label>
                      <input type="file" name='driving_license' ref={divingLicense} accept='application/pdf' onChange={drivingLicenseHandler} className={`${dL ? style.fileError : style.normal}`}/>
                  </div>
                  <div className={style.file}>
                      <label> Upload the IRCC Certificate</label>
                      <input type="file" name="ircc_certificate" ref={irccCertificate} accept='application/pdf' onChange={irccCertificateHandler} className={`${ircc ? style.fileError : style.normal}`}/>
                  </div>
                  <div>
                  </div>
              </div>
            </div>
            <div className={style.address}>
                <Autocomplete
                  apiKey="AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU"
                  style={{ width: "100%" }}
                  onPlaceSelected={(place) => {
                    setAddress(place.formatted_address);
                  }}
                  options={{
                    componentRestrictions: { country: ["ca","in"], },
                    types: ["geocode", "establishment"],
                  }}
                  placeholder='Company Address'
                />
                <label>Company Address</label>
            </div>
            <div className={`${style.address} ${style.mt}`}>
                <div className={style.question}>
                  <input type="checkbox" ref={refCheckboxOne} onChange={appointmentHandler} name="appointment" value={appointment} />
                  <span>Are you ok to book appointment with customers ?</span>
                </div>
                <div className={style.question}>
                  <input type="checkbox" ref={refCheckboxTwo} onChange={notaryHandler} name="notary_tested_license" value={notary_tested_license} />
                  <span>Do you have License for Notary tested ?</span>
                </div>
            </div>
            <div className={style.agentNow}>
              <button className={`${isLoading && style.disableBtn}`} onClick={submitHandler} type='button'> Submit </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      {
            isLoading && <Loader />
            
        }
      <ToastContainer />
    </>
  )
}

export default AgentForm