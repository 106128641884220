import React,{useEffect} from 'react'
import {useNavigate,useSearchParams} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/partialpages/Footer';
import UserHeader from '../components/partialpages/UserHeader';
import { Base64 } from '../EncryptDecrypt';
import "./styles.css"

function PaymentPage() {

  const navigate = useNavigate()
  const [queryParams,setQueryParams] = useSearchParams()

  const urlEncode = queryParams.get("code")

  useEffect(() =>{
      if(queryParams.get("flag") !== null){
          toast.success(queryParams.get("msg"),{
              position:"top-right",
              autoClose: 1000,
              closeOnClick: true,
          })
          setTimeout(() =>{
              queryParams.delete("flag")
              setQueryParams(queryParams)
          },500)
      }       
  },[])

  const payHandler = () =>{
    navigate(`/pay?code=${urlEncode}`)
  }
  const payLaterHandler = () =>{
    navigate(`/my_applications`)
  }
  return (
    <>
    <UserHeader />
      <div className='container-pay'>
        <div className="companyHeader">
          <div className="logo">
              <img src="https://tiffinhub.ca/assets/images/StackImmigration_Logo.png" alt="logo" />
          </div>
          <h1 className="name">Stack Immigration</h1>
        </div>
        <div className='proceed-msg'>
            <h2>Thanks for submitting the application.<br></br> Please proceed with the payment</h2>
            <button onClick={payHandler}>Proceed to Payment</button>
            <button onClick={payLaterHandler}>Pay Later</button>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default PaymentPage